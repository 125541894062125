.App-header {
  background-color: var(--secondary-bg);
}

.navbar {
  padding: 20px 0;
}

.navbar ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: var(--text-primary);
  text-decoration: none;
  font-size: 1.2em;
  padding: 10px 20px;
  position: relative;
  transition: color 0.3s ease;
}

.navbar a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border: 2px solid var(--accent);
  border-radius: 5px;
  transition: all 0.4s ease;
  box-sizing: border-box;
}

.navbar a:hover::before {
  width: 100%;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--primary-bg);
  color: var(--text-primary);
}

.App-footer {
  background-color: var(--border-color);
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 0px;
  text-align: center;
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.social-item {
  display: flex;
  align-items: center;
}

.social-item p {
  margin: 0;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.social-item p a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-item p a:hover {
  color: var(--accent);
}

.App-footer p {
  margin-top: 1px;
  font-size: 14px;
  color: var(--text-secondary);
}

.App-footer p a {
  color: var(--text-secondary);
}

.App-footer p a:hover {
  color: var(--accent);
}

html,
body {
  overflow: hidden;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
