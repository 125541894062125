html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  font-family: Arial, sans-serif;
  color: var(--text-primary);
  overflow: auto;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  background-color: var(--navbar-bg);
}

.App-logo {
  height: 60px;
}

.App-nav {
  display: flex;
  opacity: 0;
  animation: fadeIn 1s 0.6s forwards;
}

.App-nav a {
  color: var(--text-primary);
  margin: 0 20px;
  text-decoration: none;
  font-size: 22px;
  transition: color 0.3s ease;
}

.App-nav a:hover {
  text-decoration: underline;
  color: var(--accent);
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  padding: 20px;
  opacity: 0;
  animation: fadeIn 1s 0.9s forwards;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  width: 100%;
  gap: 30px;
}

.about-text {
  flex: 1;
  text-align: left;
  max-width: 700px;
  padding: 20px;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  margin-top: 0;
}

.about-text h1,
.about-text h2,
.about-text h3,
.about-text p {
  margin: 10px 0;
  line-height: 1.6;
}

.about-text h1 {
  font-size: 4em;
  font-weight: bold;
  color: var(--text-primary);
}

.about-text h2 {
  color: var(--text-secondary);
  font-size: 2em;
}

.about-text h3 {
  color: var(--text-tertiary);
  font-size: 1.5em;
}

.about-text p {
  color: var(--text-secondary);
  font-size: 1.15em;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 1s 1.2s forwards;
}

.about-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media only screen and (max-width: 767px) {
  .App-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }

  .App-logo {
    height: 50px;
    margin-bottom: 10px;
  }

  .App-nav {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .App-nav a {
    font-size: 18px;
    margin: 5px 10px;
  }

  .about-section {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .about-container {
    flex-direction: column;
    gap: 20px;
  }

  .about-text h1 {
    font-size: 2.5em;
  }

  .about-text h2 {
    font-size: 1.8em;
  }

  .about-text h3 {
    font-size: 1.3em;
  }

  .about-text p {
    font-size: 1em;
  }

  .about-image img {
    max-width: 100%;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .App-header {
    padding: 15px 20px;
  }

  .App-logo {
    height: 45px;
  }

  .App-nav a {
    font-size: 16px;
    margin: 5px 8px;
  }

  .about-section {
    padding: 15px;
  }

  .about-container {
    gap: 15px;
  }

  .about-text h1 {
    font-size: 2em;
  }

  .about-text h2 {
    font-size: 1.6em;
  }

  .about-text h3 {
    font-size: 1.2em;
  }

  .about-text p {
    font-size: 0.9em;
  }

  .about-image img {
    max-width: 100%;
    border-radius: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
