html,
body {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  color: var(--text-primary);
}

#projects-section {
  padding: 50px 20px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s 0.2s forwards;
}

#projects-section h1 {
  font-size: 2.5em;
  color: var(--text-primary);
  margin-bottom: 30px;
  opacity: 0;
  animation: fadeIn 1s 0.4s forwards;
}

.work-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  opacity: 0;
  animation: fadeIn 1s 0.6s forwards;
}

.work-card {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s 0.8s forwards;
}

.work-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.work-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-section {
  text-align: left;
  flex-grow: 1;
}

.right-section {
  text-align: right;
  flex-shrink: 0;
  min-width: 150px;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  opacity: 0;
  animation: fadeIn 1s 0.6s forwards;
}

.work-card,
.project-card {
  background-color: var(--secondary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s 0.8s forwards;
}

.work-card:hover,
.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.more-coming-soon {
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s 1s forwards;
}

.work-card h2,
.project-card h2 {
  font-size: 1.4em;
  color: var(--text-primary);
  margin: 10px 0;
  font-weight: bold;
}

.work-card h3,
.project-card h4 {
  font-size: 1.2em;
  color: var(--text-secondary);
  margin: 5px 0;
}

.work-card p,
.project-card p {
  font-size: 1em;
  color: var(--text-secondary);
  line-height: 1.5;
}

.project-image,
.work-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.popup-image {
  height: 500px;
  margin: 10px 0;
  border-radius: 10px;
  object-fit: cover;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.popup-overlay.active {
  visibility: visible;
  opacity: 1;
}

.popup-container {
  position: relative;
  background: var(--secondary-bg);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 65%;
  animation: popup-fade-in 0.3s ease-out;
}

@keyframes popup-fade-in {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-container button {
  margin-top: 20px;
  padding: 10px 20px;
  background: var(--accent);
  color: var(--primary-bg);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-container button:hover {
  background: var(--border-color);
}

.content h2,
.content h3,
.content h4 {
  margin: 5px 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 1280px) and (min-height: 800px) {
  #projects-section {
    padding: 60px 30px;
  }

  #projects-section h1 {
    font-size: 3em;
  }

  .work-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
  }

  .project-container {
    max-width: 1200px;
  }

  .work-card,
  .project-card {
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .work-card h2,
  .project-card h2 {
    font-size: 1.6em;
  }

  .work-card h3,
  .project-card h4 {
    font-size: 1.4em;
  }

  .work-card p,
  .project-card p {
    font-size: 1.1em;
  }

  .work-card img,
  .project-image {
    height: 250px;
  }

  .popup-image {
    width: 1200px;
  }

  .popup-container {
    width: 80%;
  }
}
